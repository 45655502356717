//-------------
// Terms Page
//-------------

// PACKAGES
import React from "react"
import { Link } from "gatsby"

// COMPONENTS
import Sidebar from "../layout/Terms"

// COMPONENT BUILD
class TermsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            date: new Date().toLocaleString(),
        }
    }
    // JSX TEMPLATE
    render() {
        return (
            <Sidebar>
                <article className="px-4 py-5 shadow col-lg-10 p-md-6">
                    <h1 className="mb-4">Terms</h1>
                    <p className="mb-5">Effective date: {this.state.date}</p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Introduction
                    </h3>
                    <p>Please read these Terms of Service ("Terms") carefully before using  <Link to="/">https://informeasy.com</Link> website (the "service") operated by Tamas Gabor Media Ltd. ("us", 'we", "our").</p>

                    <p>By accessing or using any part of the site, you agree to be bound by these Terms. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.</p>

                    <p>If you are using our services on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these Terms of Service.</p>

                    <p>We reserve the right to update, change or replace any part of these Terms by posting updates or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>

                    <p>By agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence.</p>

                    <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                    You must not transmit any worms or viruses or any code of a destructive nature.
                    A breach or violation of any of the Terms will result in an immediate termination of your services.</p>

                    <p>You may not access the service if you are a direct competitor of Informeasy, except with our prior written consent. You may use the Service only for your business purposes.</p>

                    <p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms at any time by notifying us that you no longer wish to use our services.</p>

                    <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice.</p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Personal information
                    </h3>
                    <p>
                    Before you continue using our website we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Copyright
                    </h3>
                    <p>
                    Other than the content you own, under these Terms, Tamas Gabor Media Ltd. and/or its licensors own all the intellectual property (digital downloads, images, texts, graphics, logos) rights and materials contained in this website.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Personal information
                    </h3>
                    <p>
                    Before you continue using our website we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Applicable Law
                    </h3>
                    <p>
                    By visiting this website, you agree that the laws of the United Kingdom, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between Tamas Gabor Media Ltd. and you, or its business partners and associates.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Accuracy of materials
                    </h3>
                    <p>
                    The materials appearing on Informeasy's website could include technical, typographical, or photographic errors that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We do not warrant that any of the materials on the website are accurate, complete or current. Any reliance on the material on this site is at your own risk.
                    </p>
                    <p>
                    We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order). However we do not make any commitment to update the materials.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Modifications to the service
                    </h3>
                    <p>  
                    Prices for our products are subject to change without notice. You agree that from time to time we may remove the service for indefinite periods of time or modify or discontinue the service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the service.
                    </p>
                    <p>  
                    We do not guarantee, represent, or warrant that your use of our service will be uninterrupted or error-free. You agree that your use of, or inability to use, the service is at your sole risk.
                    </p>

                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Products or services
                    </h3>
                    <p>  
                    Certain products or services may be available exclusively online through the website.
                    We have made every effort to display the colours and images of our products that appear at the store as accurately as possible. We cannot guarantee that your monitor's display of any colour will be accurate.
                    </p>
                    <p>  
                    We reserve the right, but are not obligated, to limit the sales of our products or services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. 
                    </p>
                    <p>
                    We do not guarantee that the quality of our products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the service will be corrected.
                    </p>
                    <p>
                    We may also, in the future, offer new services or features through the website. Such new features or services shall also be subject to these Terms.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Payment and pricing
                    </h3>
                    <p className="mb-2">
                    All payments are made in advance.
                    We accept the following methods of payment:
                    </p>
                    <ul className="mb-4">
                        <li>
                        PayPal
                        </li>
                        <li>
                        Card payments (Visa, Mastercard, American Express)
                        </li>
                    </ul>
                    <p className="mb-2">
                    The total price for a product on our website is made up of:
                    </p>
                    <ul className="mb-4">
                        <li>
                        Designer fee
                        </li>
                        <li>
                        Printing fee
                        </li>
                    </ul>
                    <p>
                    You are responsible for all costs of currency conversion relating to a transaction on Informeasy's website. Your financial institution does the currency conversion and may charge you additional fees (we don’t control either the conversion rates or your financial institution’s fees).
                    </p>
                    <p>
                    Refunds may be claimed in certain situations detailed in our <Link to="/refunds">refund policy.</Link>
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    User Account
                    </h3>
                    <p>
                    If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.
                    </p>
                    <p>
                    We may, without any obligation, monitor, edit, or remove content that we determine unlawful, offensive, threatening, pornographic, obscene, or otherwise objectionable or violates any party’s intellectual property or these Terms.
You agree that by submitting content you will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right.
                    </p>
                    <p>
                    You are solely responsible for any submissions you make and their accuracy. We shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Customer Data.
                    </p>
                    <p>
                    Upon termination, your right to access or use Customer Data immediately ceases, and we shall have no obligation to maintain or forward any Customer Data.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Liability
                    </h3>
                    <p>
                    In no case shall we be liable for any injury, loss, claim, or any direct or indirect, incidental or consequential damages of any kind, including lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages arising from your use of any of the service or any products purchased through this website.
                    </p>

                    
                </article>
            </Sidebar>
        )
    }
}

export default TermsPage
